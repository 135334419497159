import { Photo } from "../photo";

export function Zines() {
    return (
        <>
            <p style={{
                marginBottom: "3rem",
                padding: "1rem"
            }}>
                Dez estórias e ilustrações em duas zines sobre pessoas que implodem e pessoas que se explodem.
            </p>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    margin: "auto",
                    maxWidth: "800px"
                }}>
                <Photo imagePath="zines/zine04.jpg" />
                <Photo imagePath="zines/zine05.jpg" />
            </div>

            <div
                style={{ marginTop: "3rem" }}
            >

                <div
                    style={{ marginBottom: "1rem" }}>
                    <div>Disponíveis para venda.</div>
                    <div>Formato A6</div>
                    <div>Preço: 2€ / unidade</div>

                </div>

                <div>tresapeste@gmail.com</div>
            </div>
        </>
    );
}